import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/feasibility-projects",
    meta: { authOnly: true },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { authOnly: false },
  },
  {
    path: "/feasibility-projects",
    name: "FeasibilityProjects",
    component: () => import("@/views/FeasibilityProjects.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/feasibility-project-details/:id",
    name: "FeasibilityProjectDetails",
    component: () => import("@/views/FeasibilityProjectDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/active-projects",
    name: "ActiveProjects",
    component: () => import("@/views/ActiveProjects.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/active-project-details/:id",
    name: "ActiveProjectDetails",
    component: () => import("@/views/ActiveProjectDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/archive-projects",
    name: "ArchivedProjects",
    component: () => import("@/views/ArchivedProjects.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/archive-project-details/:id",
    name: "ArchiveProjectDetails",
    component: () => import("@/views/ArchiveProjectDetails.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/orders-tracking",
    name: "OrdersTracking",
    component: () => import("@/views/OrdersTracking.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/error",
    name: "Error404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/user-account",
    name: "UseAccount",
    component: () => import("@/views/UserAccount.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/messenger",
    name: "Messenger",
    component: () => import("@/views/Messenger.vue"),
    meta: { authOnly: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn() {
  return localStorage.getItem("authTokenUser");
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authOnly)) {
    if (!isLoggedIn()) {
      store.commit("auth/SET_AUTHENTICATED", false);
      localStorage.clear();
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    // store.commit("auth/SET_AUTHENTICATED", false);
    next();
  }
});

export default router;
