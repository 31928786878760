<template>
  <div class="home text-center">
    <template v-if="!authenticated">
      <div class="container mx-auto">
        <div class="content-center">
          <h2>Welcome to user API!</h2>
        </div>
        <p>go to the login page:</p>
        <router-link :to="{ name: 'Login' }" class="login-link"
          >Login</router-link
        >
      </div>
    </template>
    <template v-else>
      <div class="content-center">
        <h2 class="green">Welcome to CURELINE</h2>
      </div>
    </template>
  </div>
</template>

<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const authenticated = computed(() => store.getters["auth/authenticated"]);

    watch(authenticated, (newValue) => {
      if (newValue) {
        router.push({ name: "FeasibilityProjects" });
      }
    });

    return { authenticated };
  },
};
</script>

<style lang="scss" scoped>
.login-link {
  display: inline-block;
  padding: 15px 30px;
  margin-top: 15px;
  font-size: 16px;
  color: var(--col-info);
  box-shadow: 0 5px 15px #afafaf;
}
</style>
